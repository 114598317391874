import React, { useEffect, useState } from "react";
import AcceptedRequest from "./AcceptedRequest";
import DeclineRequest from "./DeclineRequest";
import PendingRequest from "./PendingRequest";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux"; // Assuming you're using redux for API calls
import { getHostRequest, getHostRequestSearch } from "../../../store/hostRequest/action"; // Your action for API call

const HostRequest = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState(1);
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Call API when search changes or other dependencies
  useEffect(() => {
    if (search) {
      if (type === 1) {
        dispatch(getHostRequestSearch(activePage, rowsPerPage, 1, search));
      } else if (type === 2) {
        dispatch(getHostRequestSearch(activePage, rowsPerPage, 2, search));
      } else if (type === 3) {
        dispatch(getHostRequestSearch(activePage, rowsPerPage, 3, search));
      }
    } else {
      if (type === 1) {
        dispatch(getHostRequest(activePage, rowsPerPage, 1));
      } else if (type === 2) {
        dispatch(getHostRequest(activePage, rowsPerPage, 2));
      } else if (type === 3) {
        dispatch(getHostRequest(activePage, rowsPerPage, 3));
      }
    }

  }, [activePage, rowsPerPage, type, search, dispatch]);

  const handleSearch = () => {
    // Make sure the API call happens when search is triggered
    dispatch(getHostRequest(activePage, rowsPerPage, type === "Pending" ? 1 : type === "Accepted" ? 2 : 3, search));
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Host Request</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Redeem
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pending Redeem
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <div className="my-2 mb-4">
            <button
              type="button"
              className={`btn btn-sm ${type === 1 ? "btn-info" : "disabledBtn"}`}
              onClick={() => setType(1)}
            >
              <span className="">Pending</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${type === 2 ? "btn-danger" : "disabledBtn"} ms-3`}
              onClick={() => setType(2)}
            >
              <span className="">Accepted</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${type === 3 ? "btn-success" : "disabledBtn"} ms-3`}
              onClick={() => setType(3)}
            >
              <span className="">Declined</span>
            </button>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
          <form action="">
            <div className="input-group mb-3 border rounded-pill">
              <input
                type="search"
                id="searchBar"
                autoComplete="off"
                placeholder="What're you searching for?"
                aria-describedby="button-addon4"
                className="form-control bg-none border-0 rounded-pill searchBar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-prepend border-0" onClick={handleSearch}>
                <div id="button-addon4" className="btn text-danger">
                  <i className="fas fa-search mt-2"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {type === 1 && <PendingRequest activePage={activePage} setActivePage={setActivePage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} search={search} setSearch={setSearch} type={type} />}
      {type === 2 && <AcceptedRequest activePage={activePage} setActivePage={setActivePage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} search={search} setSearch={setSearch} type={type} />}
      {type === 3 && <DeclineRequest activePage={activePage} setActivePage={setActivePage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} search={search} setSearch={setSearch} type={type} />}
    </>
  );
};

export default HostRequest;
