import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  CLOSE_INFO_DIALOGUE_SETTLEMENT,
  OPEN_DIALOGUE_SETTLEMENT,
  OPEN_INFO_DIALOGUE_SETTLEMENT,
} from "../../../store/settlement/settlement.type";
import {
  getAgencySettlement,
  paidSettlement,
} from "../../../store/settlement/settlement.action";
import SettlementActionDialogue from "./SettlementActionDialogue";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Pagination from "../../../pages/Pagination";
import { Cancel } from "@material-ui/icons";
import Select from "react-select";
import axios from "axios";
import SelectCustomStyles from "../../../util/CustomStyle";

const SettlementPending = (props) => {
  const {
    agencySettlement,
    historyCount,
    dialog: open,
    dialogData,
    dialogType,
    dialog1,
    dialogData1,
  } = useSelector((state) => state.settlement);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [detailsId, setDetailsId] = useState("");
  const [payment, setPayment] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState("All");
  const [amount , setAmount] = useState("");

  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all").then((res) => {
      setCountryData(res.data);
    });
  }, []);

  useEffect(() => {
    if (countryData?.length > 0) {
      const formattedOptions = countryData.map((country) => ({
        label: country?.name?.common,
        value: country?.name?.common,
      }));

      // Add the "All" option at the beginning of the array
      setOptions([{ label: "All", value: "All" }, ...formattedOptions]);
    }
  }, [countryData]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    const type = 1;
    const payload = { startDate, endDate, type, country };
    dispatch(getAgencySettlement(payload));
  }, [startDate, endDate, country, dispatch]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePayment = () => {
    dispatch(paidSettlement(detailsId)).then((res) => {
      if (res?.status) {
        setOpenModal(false);
      }
    });
  };

  const handleSearch = () => {
    const value = search.trim().toLowerCase();
    if (value) {
      const filteredData = agencySettlement.filter((data) =>
        ["name", "uniqueId", "agencyCode", "totalCoin"].some((key) =>
          data[key]?.toLowerCase().includes(value)
        )
      );
      setData(filteredData);
    } else {
      setData(agencySettlement);
    }
  };
  const closePopup = () => {
    setOpenModal(false);
    setPayment("");
  };

  const closePopupInfo = () => {
    dispatch({ type: CLOSE_INFO_DIALOGUE_SETTLEMENT });
  };

  useEffect(() => {
    if (countryData?.length > 0) {
      const formattedOptions = countryData.map((country) => ({
        label: country?.name?.common,
        value: country?.name?.common,
      }));

      setOptions([{ label: "All", value: "All" }, ...formattedOptions]);
    }
  }, [countryData]);
  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all").then((res) => {
      setCountryData(res.data);
    });
  }, []);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Agency Pending Settlement</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        sx={{ maxWidth: "400px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">
            {" "}
            Bank Details
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Bank Details : </label>
                    <input
                      label={`Bank Details`}
                      id={`bankDetails`}
                      type="text"
                      class="form-control"
                      value={payment ? payment : "-"}
                      placeholder={`Enter Bank Details`}
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Amount : </label>
                    <input
                      label={`Amount`}
                      id={`amount`}
                      type="text"
                      class="form-control"
                      value={amount ? amount : "-"}
                      placeholder={`Enter amount`}
                    />
                  </div>
                </div>
                <div className={"mt-3 pt-3"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handlePayment}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {dialog1 && (
        <Dialog
          open={dialog1}
          aria-labelledby="responsive-dialog-title"
          onClose={closePopupInfo}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          sx={{ maxWidth: "400px" }}
        >
          <DialogTitle id="responsive-dialog-title">
            <span className="text-danger font-weight-bold h4">
              {" "}
              Payout Details
            </span>
          </DialogTitle>

          <IconButton
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <Tooltip title="Close">
              <Cancel className="text-danger" onClick={closePopupInfo} />
            </Tooltip>
          </IconButton>
          <DialogContent>
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <div className="row">
                    <div class="form-group col-12 mt-3">
                      <label class="mb-2 text-gray">Account Name : </label>
                      <input
                        label={`Account Name`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control"
                        value={
                          dialogData1?.data?.agency?.accountName
                            ? dialogData1?.data?.agency?.accountName
                            : "-"
                        }
                        placeholder={`Enter Account Name`}
                      />

                      <label class="mb-2 text-gray mt-3">
                        Account Number :{" "}
                      </label>

                      <input
                        label={`Account Number`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control mt-1"
                        value={
                          dialogData1?.data?.agency?.accountNumber
                            ? dialogData1?.data?.agency?.accountNumber
                            : "-"
                        }
                        placeholder={`Enter Account Number`}
                      />

                      <label class="mb-2 text-gray mt-3">
                        Payment Method :{" "}
                      </label>

                      <input
                        label={`Payment Method`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control mt-1"
                        value={
                          dialogData1?.data?.agency?.PaymentMethod
                            ? dialogData1?.data?.agency?.PaymentMethod
                            : "-"
                        }
                        placeholder={`Enter Payment Method`}
                      />
                      <label class="mb-2 text-gray mt-3">
                        Amount :{" "}
                      </label>

                      <input
                        label={`Payment Method`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control mt-1"
                        value={
                          dialogData1?.data?.dollar
                            ? dialogData1?.data?.dollar
                            : "-"
                        }
                        placeholder={`Enter Payment Method`}
                      />
                    </div>
                  </div>
                  <div className={"mt-3 pt-3"}>

                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 btn-round float__right icon_margin me-2"
                      onClick={closePopupInfo}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-round float__right btn-danger "
                      onClick={handlePayment}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <Select
                    value={options?.find((option) => option?.value === country)}
                    options={options}
                    className="select_country"
                    placeholder="Select Country"
                    styles={SelectCustomStyles}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption?.value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-prepend border-0">
                        <div
                          id="button-addon4"
                          className="btn text-danger"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow">
              <table className="table table-striped">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Month</th>
                    <th>Agency</th>
                    <th>Country</th>
                    <th>Agency Code</th>
                    <th>Agency and Host Total Coin</th>
                    <th>Commission</th>
                    <th>Commission (%)</th>
                    <th>Bonus/Penalty</th>
                    <th>Total Coin</th>
                    {/* <th>Total Amount</th> */}
                    {/* <th>Amount ($)</th> */}
                    <th>Payout details</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.length > 0 ? (
                    data.map((value, agencyIndex) => (
                      <tr key={`${agencyIndex}-${agencyIndex}`}>
                        <td>
                          {(currentPage - 1) * rowsPerPage + agencyIndex + 1}
                        </td>
                        <td>{value?.startDate}</td>
                        <td>{value?.endDate}</td>
                        <td>{value?.month}</td>
                        <td><img src={value?.agency?.image} height={20} width={20} style={{ borderRadius: "50%" }} />{value?.agency?.name}</td>
                        <td>{value?.agency?.country}</td>
                        <td>{value?.agency?.agencyCode}</td>
                        <td>{value?.commissionCoinEarned + value?.coinEarned}</td>
                        <td>{value?.commissionCoinEarned}</td>
                        <td>{value?.agencyCommissionPercentage}</td>
                        {/* <td>{value?.totalCoinEarned || 0}</td> */}
                        <td>{value?.bonusOrPenltyAmount}</td>
                        <td>{value?.amount}</td>
                        <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => {
                                //   setOpenModal(true);
                                //   setPayment(value?.agency?.bankDetails)
                                //   setDetailsId(value?._id)
                                dispatch({
                                  type: OPEN_INFO_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                  },
                                })
                              }}

                            >
                              Pay
                            </button>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() =>
                                dispatch({
                                  type: OPEN_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                    type: "settlementActionDialogue",
                                  },
                                })
                              }
                            >
                              Action
                            </button>
                          </Tooltip>
                        </td>


                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <SettlementActionDialogue />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencySettlement, paidSettlement })(
  SettlementPending
);
