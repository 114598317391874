import { Link } from "react-router-dom";
// import Table from "../../extra/Table";
// import Button from "../../extra/Button";
// import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import Searching from "../../extra/Searching";
import { CLOSE_INFO_DIALOGUE_SETTLEMENT, OPEN_DIALOGUE_SETTLEMENT, OPEN_INFO_DIALOGUE_SETTLEMENT } from "../../../store/settlement/settlement.type";
import {
  getHostSettlement,
  paidSettlement,
} from "../../../store/hostSettlement/hostSettlement.action";
import SettlementActionDialogue from "./SettlementActionDialogue";
import BonusInfo from "./BonusInfo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "@material-ui/core";
import Pagination from "../../../pages/Pagination";
import Select from "react-select";
import axios from "axios";
import SelectCustomStyles from "../../../util/CustomStyle";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";


const SettlementPending = (props) => {
  const { agencySettlement, historyCount, dialog1, dialogData1 } = useSelector(
    (state) => state.settlement
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(1);
  const [sort, setSort] = useState("");
  const [typeSort, setTypeSort] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState("All");
  const [options, setOptions] = useState([]);



  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all").then((res) => {
      setCountryData(res.data);
    });
  }, []);

  useEffect(() => {
    if (countryData?.length > 0) {
      const formattedOptions = countryData.map((country) => ({
        label: country?.name?.common,
        value: country?.name?.common,
      }));

      // Add the "All" option at the beginning of the array
      setOptions([{ label: "All", value: "All" }, ...formattedOptions]);
    }
  }, [countryData]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    let type = 1;
    const payload = {
      startDate,
      endDate,
      type,
      country
    };
    dispatch(getHostSettlement(payload));
  }, [startDate, endDate, currentPage, country, rowsPerPage, search, sort, typeSort]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePayment = (id) => {
    dispatch(paidSettlement(id));
  };

  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId?._id,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
      agencyName: data?.agencyId?.name,
    };
    history.push("/admin/agencySettlement", { state: state });
  };

  const handleSearch = () => {
    const value = search.trim().toLowerCase();

    if (value) {
      const filteredData = agencySettlement.filter((data) => {
        return (
          data?.name?.toLowerCase().includes(value) ||
          data?.uniqueId?.toString().includes(value) ||
          data?.agencyCode?.toString().includes(value) ||
          data?.totalCoin?.toString().includes(value)
        );
      });
      setData(filteredData);
    } else {
      setData(agencySettlement);
    }
  };

  const closePopupInfo = () => {
    dispatch({ type: CLOSE_INFO_DIALOGUE_SETTLEMENT });
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{index + 1}</span>,
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "	Agency Code", body: "agency.agencyCode" },
    { Header: "Agency Name", body: "agency.name" },
    { Header: "Start Date ", body: "startDate" },
    {
      Header: "End Date",
      Cell: ({ row }) => <span className="text-nowrap">{row.endDate}</span>,
    },
    {
      Header: "Revenue",
      body: "amount",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary fw-bold"
        >
          {row?.amount}
        </span>
      ),
    },
    {
      Header: "commission",
      body: "commissionCoinEarned",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>
          {row?.commissionCoinEarned} &nbsp;
          <span className="text-success">
            ({row?.agencyCommisionPercentage + "%"})
          </span>
        </span>
      ),
    },
    {
      Header: "Total Revenue",
      body: "totalCoinEarned",
      sorting: { type: "server" },
    },
    {
      Header: "Penalty/Bonus",
      body: "penltyOrBonus",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE_SETTLEMENT,
              payload: { type: "bonusInfo", data: row },
            });
          }}
          className={`cursor ${row?.bonusOrPenltyAmount > 0
            ? "text-success"
            : row?.bonusOrPenltyAmount === 0
              ? "text-dark"
              : "text-danger"
            }`}
        >
          {row?.bonusOrPenltyAmount}
        </span>
      ),
    },
    {
      Header: "Final",
      body: "amount",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor textPink boxCenter fw-bold "
        >
          {row?.amount}
        </span>
      ),
    },
    {
      Header: "Amount",
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary"
        >
          {row.dollar + "$"}
        </span>
      ),
      sorting: { type: "server" },
    },
    {
      Header: "Pay",
      body: "pay",
      Cell: ({ row }) => (
        <button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnName={`Pay`}
          // btnIcon={`fa-solid fa-info`}
          onClick={() => handlePayment(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "100px" }}
        />
      ),
    },
    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <div>
          <button
            newClass={` boxCenter userBtn text-white fs-6 border`}
            btnColor={`bgPink`}
            btnName={`Action`}
            // btnIcon={`fa-solid fa-info`}
            // onClick={() => handleAction(row, "hostInfo")}
            onClick={() => {
              dispatch({
                type: OPEN_DIALOGUE_SETTLEMENT,
                payload: {
                  data: row,
                  id: row._id,
                  type: "settlementActionDialogue",
                },
              });
            }}
            style={{ borderRadius: "5px", margin: "auto", width: "100px" }}
          />
        </div>
      ),
    },
    // add more columns as needed
  ];

  const handleChildValue = (value) => {
    setTypeSort(typeSort === -1 ? 1 : -1);
    setSort(value);
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Host Pending Settlement</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {dialog1 && (
        <Dialog
          open={dialog1}
          aria-labelledby="responsive-dialog-title"
          onClose={closePopupInfo}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          sx={{ maxWidth: "400px" }}
        >
          <DialogTitle id="responsive-dialog-title">
            <span className="text-danger font-weight-bold h4">
              {" "}
              Payout Details
            </span>
          </DialogTitle>

          <IconButton
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <Tooltip title="Close">
              <Cancel className="text-danger" onClick={closePopupInfo} />
            </Tooltip>
          </IconButton>
          <DialogContent>
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <div className="row">
                    <div class="form-group col-12 mt-3">
                      <label class="mb-2 text-gray">Account Name : </label>
                      <input
                        label={`Account Name`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control"
                        value={
                          dialogData1?.data?.host?.accountName
                            ? dialogData1?.data?.host?.accountName
                            : "-"
                        }
                        placeholder={`Enter Account Name`}
                      />

                      <label class="mb-2 text-gray mt-3">
                        Account Number :{" "}
                      </label>

                      <input
                        label={`Account Number`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control mt-1"
                        value={
                          dialogData1?.data?.host?.accountNumber
                            ? dialogData1?.data?.host?.accountNumber
                            : "-"
                        }
                        placeholder={`Enter Account Number`}
                      />

                      <label class="mb-2 text-gray mt-3">
                        Payment Method :{" "}
                      </label>

                      <input
                        label={`Payment Method`}
                        id={`bankDetails`}
                        type="text"
                        class="form-control mt-1"
                        value={
                          dialogData1?.data?.host?.PaymentMethod
                            ? dialogData1?.data?.host?.PaymentMethod
                            : "-"
                        }
                        placeholder={`Enter Payment Method`}
                      />
                    </div>
                  </div>
                  <div className={"mt-3 pt-3"}>
                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                      onClick={closePopupInfo}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">

                  <Select
                    value={options?.find((option) => option?.value === country)}
                    options={options}
                    className="select_country"
                    placeholder="Select Country"
                    styles={SelectCustomStyles}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption?.value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-prepend border-0">
                        <div
                          id="button-addon4"
                          className="btn text-danger"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow">
              <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table className="table table-striped">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    {/* <th>BD </th> */}
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Month</th>
                    <th>Agency</th>
                    <th>Agency Code</th>
                    <th>Host</th>
                    <th>Country</th>
                    <th>Host Id</th>
                    <th>Days</th>
                    <th>Duration</th>
                    <th>Host Total Coin</th>
                    <th>Pay</th>
                    <th>Info</th>

                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.length > 0 ? (
                    data.map((value, agencyIndex) => (
                      <tr key={`${agencyIndex}-${agencyIndex}`}>
                        <td>
                          {(currentPage - 1) * currentPage + agencyIndex + 1}
                        </td>
                        <td>{value?.startDate || "-"}</td>
                        <td>{value?.endDate || "-"}</td>
                        <td>{value?.month || "-"}</td>
                        <td><img src={value?.agency?.image} height={20} width={20} style={{ borderRadius: "50%" }} />{value?.agency?.name || "-"}</td>
                        <td>{value?.agency?.agencyCode || "-"}</td>
                        <td><img src={value?.hostImage} height={20} width={20} style={{ borderRadius: "50%" }} />{value?.hostName || "-"}</td>
                        <td>{value?.country || "-"}</td>
                        <td>{value?.hostUniqueId || "-"}</td>
                        <td>{value?.validDays || "-"}</td>
                        <td>{value?.liveDuration || "-"}</td>
                        <td>{value?.amount || "-"}</td>
                        <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handlePayment(value?._id)}
                            >
                              Pay
                            </button>
                          </Tooltip>
                        </td>

                        <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() =>
                                dispatch({
                                  type: OPEN_INFO_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                  },
                                })
                              }
                            >
                              <i className="fas fa-info-circle fa-lg"></i>
                            </button>
                          </Tooltip>
                        </td>

                        {/* <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => {
                                dispatch({
                                  type: OPEN_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                    type: "settlementActionDialogue",
                                  },
                                });
                              }}
                            >
                              Action
                            </button>
                          </Tooltip>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <SettlementActionDialogue /> */}

              {/* <Pagination
                  activePage={activePage}
                  rowsPerPage={page}
                  userTotal={total}
                  handleRowsPerPage={handleChangePage}
                  handlePageChange={handleChangePage}
                /> */}
            </div>
            {/* <BonusInfo /> */}
          </div>
        </div>
      </div>
      {/* <div className="mainSettlementTable">
        {dialogue && dialogueType === "settlementActionDialogue" && (
          <SettlementActionDialogue />
        )}
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Pending Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-6 my-3">
                <Searching
                  type={"server"}
                  data={agencySettlement}
                  setData={setData}
                  setSearchData={setSearch}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain ">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={data?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={historyCount}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div> */}
    </>
  );
};

export default connect(null, { getHostSettlement, paidSettlement })(
  SettlementPending
);
