export const GET_SUB_ADMIN = "GET_SUB_ADMIN";
export const CREATE_SUB_ADMIN = "CREATE_SUB_ADMIN";

export const UPDATE_SUB_ADMIN = "UPDATE_SUB_ADMIN";


export const DELETE_SUB_ADMIN = "DELETE_SUB_ADMIN";

export const OPEN_SUB_ADMIN_DIALOGUE = "OPEN_SUB_ADMIN_DIALOGUE";
export const OPEN_PERMISSION_DIALOGUE = "OPEN_PERMISSION_DIALOGUE";
export const CLOSE_SUB_ADMIN_DIALOGUE = "CLOSE_SUB_ADMIN_DIALOGUE";
export const CLOSE_PERMISSION_DIALOGUE = "CLOSE_PERMISSION_DIALOGUE";
