export const GET_HOST_REQUEST = "GET_HOST_REQUEST";

export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";


export const OPEN_REASON_DIALOGUE = "OPEN_REASON_DIALOGUE";
export const CLOSE_REASON_DIALOGUE = "CLOSE_REASON_DIALOGUE";

export const OPEN_DOCUMENT_DIALOGUE = "OPEN_DOCUMENT_DIALOGUE";
export const CLOSE_DOCUMENT_DIALOGUE = "CLOSE_DOCUMENT_DIALOGUE";

export const OPEN_AGENCY_CODE_DIALOGUE = "OPEN_AGENCY_CODE_DIALOGUE"
export const CLOSE_AGENCY_CODE_DIALOGUE ="CLOSE_AGENCY_CODE_DIALOGUE"