import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_PERMISSION_DIALOGUE } from "../../store/subAdmin/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addSubAdmin, updatePermissionSubAdmin, updateSubAdmin } from "../../store/subAdmin/action";

const PermissionDialogue = () => {
    const { dialog1: opens, dialogData } = useSelector((state) => state.subAdmin);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    useEffect(() => {
        if (dialogData) {
            setSelectedRoles(dialogData.roles);
        }
    }, [dialogData])

    const userRole = [
        { name: "User", path: "/admin/user", icon: "users" },
        { name: "Banner", path: "/admin/banner", icon: "image" },
        { name: "Pending Complain", path: "/admin/pendingComplain", icon: "layout" },
        { name: "Gift Category", path: "/admin/giftCategory", icon: "clock" },
        { name: "Gift", path: "/admin/gift", icon: "clock" },
        { name: "Reaction", path: "/admin/reaction", icon: "clock" },
        { name: "Entry Effect", path: "/admin/entryEffect", icon: "clock" },
        { name: "Avtar Frame", path: "/admin/avatarFrame", icon: "clock" },
        { name: "Sticker", path: "/admin/sticker", icon: "clock" },
        { name: "Theme", path: "/admin/theme", icon: "clock" },
        { name: "Post", path: "/admin/post", icon: "clock" },
        { name: "Song", path: "/admin/song", icon: "clock" },
        { name: "Hashtag", path: "/admin/hashtag", icon: "clock" },
        { name: "Fake Comment", path: "/admin/comment", icon: "clock" },
        { name: "Agency", path: "/admin/agency", icon: "user-plus" },
        { name: "Host Request", path: "/admin/hostRequest", icon: "user-check" },
        { name: "Coin Seller", path: "/admin/coinSeller", icon: "user-check" },
        { name: "Coin Plan History", path: "/admin/coinPlan/history", icon: "clock" },
        { name: "VIP Plan History", path: "/admin/vipPlan/history", icon: "clock" },
        { name: "User Redeem Request", path: "/admin/userRedeemRequest", icon: "dollar-sign" },
        { name: "Host Settlement", path: "/admin/hostpending", icon: "dollar-sign" },
        { name: "Agency Settlement", path: "/admin/pending", icon: "dollar-sign" },
        { name: "Game History", path: "/admin/gameHistory", icon: "dollar-sign" },
        { name: "Level", path: "/admin/level", icon: "dollar-sign" },
        { name: "Video", path: "/admin/video", icon: "dollar-sign" },
        { name: "Reported User", path: "/admin/reportedUser", icon: "dollar-sign" },
        { name: "Coin Plan", path: "/admin/coinPlan", icon: "dollar-sign" },
        { name: "Vip Plan", path: "/admin/vipPlan", icon: "dollar-sign" },
    ];

    const closePopup = () => {
        dispatch({ type: CLOSE_PERMISSION_DIALOGUE });
    };

    // Handle individual checkbox change
    const handleCheckboxChange = (role) => {
        const updatedRoles = selectedRoles.some((selectedRole) => selectedRole.name === role.name)
            ? selectedRoles.filter((selectedRole) => selectedRole.name !== role.name)
            : [...selectedRoles, role];

        setSelectedRoles(updatedRoles);
        setSelectAll(updatedRoles.length === userRole.length); // Update "Select All" state
    };

    // Handle "Select All" change
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedRoles([]); // Deselect all
        } else {
            setSelectedRoles(userRole); // Select all roles
        }
        setSelectAll(!selectAll); // Toggle the select all state
    };
    console.log("dialogDatadialogData", dialogData)

    const handleSubmit = () => {
        if (selectedRoles.length === 0) {
            toast.error("Please select at least one role");
        } else {
            const data = {
                roles: selectedRoles  
            };
            dispatch(updatePermissionSubAdmin(data, dialogData?._id));
            closePopup();
        }
    };

    return (
        <Dialog
            open={opens}
            aria-labelledby="responsive-dialog-title"
            onClose={closePopup}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            sx={{ maxWidth: "400px" }}
        >
            <DialogTitle id="responsive-dialog-title">
                <span className="text-danger font-weight-bold h4"> Sub Admin </span>
            </DialogTitle>

            <IconButton
                style={{
                    position: "absolute",
                    right: 0,
                }}
            >
                <Tooltip title="Close">
                    <Cancel className="text-danger" onClick={closePopup} />
                </Tooltip>
            </IconButton>
            <DialogContent>
                <div className="permissions-list">
                    {/* "Select All" checkbox */}
                    <div className="form-group">
                        <label style={{ color: "white" }}>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                            <span className="ml-2" style={{ marginLeft: "20px" }}>Select All</span>
                        </label>
                    </div>

                    {/* Individual role checkboxes */}
                    {userRole.map((role, index) => (
                        <div key={index} className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    value={role.name}
                                    checked={selectedRoles.some((selectedRole) => selectedRole.name === role.name)}
                                    onChange={() => handleCheckboxChange(role)}
                                />
                                <span className="ml-4" style={{ color: "white", marginLeft: "20px" }}>{role.name}</span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="mb-4">
                    <div className={"mt-5"}>
                        <button
                            type="button"
                            className="btn btn-outline-info ml-2 btn-round float__right icon_margin mb-4"
                            onClick={closePopup}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-round float__right btn-danger mb-4"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PermissionDialogue;
