import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHostRequest } from "../../../store/hostRequest/action";
import dayjs from "dayjs";
import Pagination from "../../../pages/Pagination";
import { Cancel } from "@material-ui/icons";
import { CLOSE_DOCUMENT_DIALOGUE, OPEN_DOCUMENT_DIALOGUE } from "../../../store/hostRequest/type";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";

const AcceptedRequest = ({activePage,setActivePage,rowsPerPage,setRowsPerPage,search,type}) => {
  const dispatch = useDispatch();
  const { request, total, dialog2, dialogData2 } = useSelector((state) => state.hostRequest);

  const [coinSort, setCoinSort] = useState(true);
  const [data, setData] = useState([]);
  // const [type, setType] = useState("Pending");
  // const [activePage, setActivePage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);


  useEffect(() => {
    dispatch(getHostRequest(activePage, rowsPerPage,type,search));
  }, [activePage, rowsPerPage,type,search]);

  useEffect(() => {
    setData(request);
  }, [request]);

  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_DOCUMENT_DIALOGUE });
  };


  return (
    <>
    {dialog2 === true && (
        <Dialog
          open={dialog2}
          aria-labelledby="responsive-dialog-title"
          onClose={() => {
            dispatch({ type: CLOSE_DOCUMENT_DIALOGUE });
          }}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          style={{ maxHeight: "600px", marginTop: "100px" }}
        >
          <DialogTitle id="responsive-dialog-title">
            <span className="text-danger font-weight-bold h4">
              View Documents
            </span>
          </DialogTitle>

          <IconButton
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <Tooltip title="Close">
              <Cancel
                className="text-danger"
                onClick={() => {
                  dispatch({ type: CLOSE_DOCUMENT_DIALOGUE });
                }}
              />
            </Tooltip>
          </IconButton>
          <DialogContent>
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <div className="mt-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <label className="mb-2 ms-2 text-gray">Personal Photo</label>
                        <img
                          src={selectedRequest?.document}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(selectedRequest?.document);
                          }}
                          height={100}
                          width={100}
                        />
                      </div>
                      <div>
                        <label className="mb-2 ms-2 text-gray">
                        ID font part
                        </label>
                        <img
                          src={selectedRequest?.document2}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(selectedRequest?.document2);
                          }}
                          height={100}
                          width={100}
                        />
                      </div>
                      <div>
                        <label className="mb-2 ms-2 text-gray">
                        ID Back part  
                        </label>
                        <img
                          src={selectedRequest?.profileImage}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(selectedRequest?.profileImage);
                          }}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                      onClick={() => {
                        dispatch({ type: CLOSE_DOCUMENT_DIALOGUE });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right"></div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Agency Name</th>
                    <th>User Name</th>
                    <th>Agency Code</th>
                    <th>CreatedAt</th>
                    <th>Document</th>

                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={
                                data?.user?.image
                                  ? data?.user?.image
                                  : data?.profileImage
                              }
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>
                            {data?.agencyName ? data?.agencyName : "-"}
                          </td>
                          <td>
                            {data?.user?.name ? data?.user?.name : data?.name}
                          </td>

                          <td>{data?.agencyCode ? data?.agencyCode : "-"}</td>

                          <td>
                            {dayjs(data?.createdAt).format("DD MMM, YYYY")}
                          </td>

                          <td>
                            <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btnname btn-sm btn-success d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    outline: "none",
                                    border: "none",
                                    borderRadius : "10px"
                                  }}
                                  onClick={() => {
                                    
                                    handleOpen(data)
                                    setSelectedRequest(data);
                                  }}
                                  
                                >
                                  <i
                                    className=""
                                    style={{
                                      fontSize: "18px",
                                      padding: "0px 15px",
                                      color : "#9A9CAB",
                                      fontWeight : 400
                                    }}
                                  >
                                    View
                                  </i>
                                </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptedRequest;
