import React, { Suspense, useEffect, useState } from "react";

// routing
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";

import { IdleTimeoutManager } from "idle-timer-manager";

//Components
import Login from "./pages/LoginPage";
import UnlockScreenPage from "./pages/UnlockScreenPage";
import Page404 from "./pages/Page404";
import Admin from "./pages/Admin";
import AuthRouter from "./util/AuthRouter";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Spinner from "./pages/Spinner";
import SubAdminCom from "./pages/SubAdminCom";

function App() {
  const dispatch = useDispatch();
  const isAuth = sessionStorage.getItem("isAuth");

  const token = sessionStorage.getItem("TOKEN");

  const key = sessionStorage.getItem("KEY");
  const { admin } = useSelector((state) => state.admin);

  useEffect(() => {
    if (token && key) {
      if (isAuth) {
        dispatch({ type: SET_ADMIN, payload: token });
      }
    }
  }, [dispatch, isAuth, token, key]);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return (window.location.href = "/");
      },
    });

    return () => {
      manager.clear();
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            <AuthRouter exact path="/" component={Login} />

            <AuthRouter exact path="/login" component={Login} />

            <AuthRouter exact path="/unlock" component={UnlockScreenPage} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/changePassword/:id"
              component={ChangePassword}
            />

            {
              isAuth && (
                admin?.isSubAdmin ? (
                  <Route path="/admin" component={SubAdminCom} />
                ) : (
                  <Route path="/admin" component={Admin} />
                )
              )
            }

            <Route component={Page404} />
          </Switch>
          <Spinner />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
