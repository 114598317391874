// SelectCustomStyles.js

const SelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    cursor: 'default', // Disable pointer cursor for the select control
    backgroundColor: '#9859FF', // Purple background for the select field
    color: '#fff',
    border: state.isFocused ? '2px solid #9f51ef' : '2px solid transparent', // Focus effect
    boxShadow: 'none',
    borderRadius: '5px',
    minWidth: '200px',
    '&:hover': {
      borderColor: '#9f51ef',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff', // White text
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff', // White dropdown arrow
    cursor: 'default', // Disable pointer cursor on the arrow
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#7b32b2' : '#1f1f1f', // Dark purple for selected, black for dropdown
    color: state.isSelected ? '#fff' : '#fff', // White text in options
    '&:hover': {
      backgroundColor: '#4f4f4f', // Darker hover effect
    },
    cursor: 'default', // Disable pointer cursor for options
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#1f1f1f', // Dark background for the dropdown
    borderRadius: '5px',
    minWidth: '200px',
    padding: '5px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff', // White placeholder
    cursor: 'default', // Disable pointer cursor for the placeholder
  }),
};

  
  export default SelectCustomStyles;
  