import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  deleteAgencyWiseHost,
  editHosttoanotherAgency,
  getAgency,
  getAgencyWiseHost,
  getHostAgencyCode,
  getHostDropdown,
  handleBlockUnblockSwitch,
  redeemEnableHost,
} from "../store/agency/action";

//routing
import { Link, useHistory } from "react-router-dom";
import { Cancel } from "@material-ui/icons";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../assets/images/male.png";

//pagination
import Pagination from "../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { warning } from "../util/Alert";
import {
  CLOSE_AGENCYWISEHOST_DIALOG,
  OPEN_AGENCYWISEHOST_DIALOG,
} from "../store/agency/type";
import { toast } from "react-toastify";

const AgencyWiseHost = (props) => {
  const history = useHistory();
  const maxDate = new Date();
  const dispatch = useDispatch();

  // const [coinSort, setCoinSort] = useState(true);
  // const [followerSort, setFollowerSort] = useState(true);
  // const [followingSort, setFollowingSort] = useState(true);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const location = useLocation();
  const [uniqueId, setUniqueId] = useState("");
  const [imagePath, setImagePath] = useState(null);
  const [agencyCode, setAgencyCode] = useState();
  const {
    agencyWiseHost,
    totalAgencyWiseHost,
    dialog1,
    dialogData1,
    agency,
    hostDropdown,
    agencyCodeDropDown,
  } = useSelector((state) => state.agency);



  const agencyData = agency;

  const [selectedRequest, setSelectedRequest] = useState(null);

  const [errors, setErrors] = useState({
    name: "",
    uniqueId: "",
    agencyCode: "",
  });

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  useEffect(() => {
 
    dispatch(
      getAgencyWiseHost(location?.state?._id, activePage, rowsPerPage, search)
    );
    dispatch(getHostDropdown());
  }, [dispatch, activePage, rowsPerPage, search]);

  useEffect(() => {
    if (dialog1 === true) {
      setAgencyCode(agencyCodeDropDown?.hostAgency?.agencyCode);
      const foundUniqueId = hostDropdown?.find(
        (item) => item?.uniqueId === dialogData1?.uniqueId
      )?.uniqueId;

      setUniqueId(foundUniqueId || ""); // Set empty string if not found
    }
  }, [dialog1, dialogData1, hostDropdown, agencyCodeDropDown]);

  useEffect(() => {
    dispatch(getAgency(1, 1000, "All"));
  }, [1, 1000]);
  //   pagination

  useEffect(() => {
    setData(agencyWiseHost);
  }, [agencyWiseHost]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleBlockUnblockSwitch_ = (userId) => {
    props.handleBlockUnblockSwitch(userId);
  };

  const handleUserInfo = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/detail");
  };
  const handleUserHistory = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/history");
  };

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    dispatch(getAgencyWiseHost(activePage, rowsPerPage, sDate, eDate));
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handleEnabledRedeem = (id) => {
    dispatch(redeemEnableHost(id));
  };

 

  const closePopup = () => {
    dispatch({ type: CLOSE_AGENCYWISEHOST_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!uniqueId) errors.uniqueId = "UniqueId Is Required!";
    if (!agencyCode) errors.agencyCode = "AgencyCode Is Required";

    if (Object.keys(errors).length) {
      return setErrors({ ...errors });
    }

    if (dialog1) {
      dispatch(
        editHosttoanotherAgency(agencyCode, uniqueId, dialogData1?._id)
      ).then((res) => {
       
        if (res?.data?.status) {
          dispatch(
            getAgencyWiseHost(
              location?.state?._id, 
              activePage,
              rowsPerPage,
              search
            )
          );

          dispatch({ type: CLOSE_AGENCYWISEHOST_DIALOG });
        }
      });
    }
  };

  const handleDelete = (id) => {

    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteAgencyWiseHost(id));
          alert("Deleted!", `Host has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOpen = (data) => {
    dispatch(getHostAgencyCode(data?.uniqueId));

    dispatch({ type: OPEN_AGENCYWISEHOST_DIALOG, payload: data });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: "#BBDEFB",
        color: "#000",
      },
    }),
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3
              className="mb-3 text-light"
              style={{ color: "#e4eeff", textTransform: "capitalize" }}
            >
              Agency Wise Host
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Agency
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Agency Wise Users
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <h6
                    style={{
                      color: "#7d7f8c",
                      textTransform: "capitalize",
                      fontSize: "20px",
                    }}
                  >
                    {`${location?.state?.name}'s Host`}{" "}
                  </h6>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                          setActivePage(1);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              {}
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Unique Id</th>
                    <th>Mobile Number</th>
                    <th>Age</th>

                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>Diamond</th>
                    <th>Coin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Follower</th>
                    <th>Following</th>
                    <th>Host Noc</th>
                    <th>Action</th>
                    {/* <th>Redeem Enable</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                     
                      return (
                        <tr key={index}>
                          <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                          <td className="d-flex">
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className=""
                            />
                            <span className="d-flex align-items-center">
                              {data?.name ? data?.name : "-"}
                            </span>
                          </td>

                          <td>{data?.uniqueId ? data?.uniqueId : "-"}</td>
                          <td>
                            {data?.mobileNumber ? data?.mobileNumber : "-"}
                          </td>
                          <td>{data?.age ? data?.age : "-"}</td>
                          <td className="text-info">
                            {data?.diamond ? data?.diamond : "-"}
                          </td>
                          <td className="text-danger">
                            {data?.rCoin ? data?.rCoin : "0"}
                          </td>

                          <td className="text-success">
                            {data?.country ? data?.country : "-"}
                          </td>
                          <td className="text-warning">
                            {data?.level?.name ? data?.level?.name : "-"}
                          </td>
                          <td>{data?.followers ? data?.followers : "0"}</td>
                          <td>{data?.following ? data?.following : "0"}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btnname btn-sm btn-success d-flex align-items-center"
                                style={{
                                  backgroundColor: "#83D8AE",
                                  outline: "none",
                                  border: "none",
                                }}
                                onClick={() => handleOpen(data)}
                              >
                                <i
                                  className=""
                                  style={{
                                    fontSize: "18px",
                                    padding: "0px 10px",
                                  }}
                                >
                                  Noc
                                </i>
                              </button>
                            </div>
                          </td>
                          <td>
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(data?._id)}
                              >
                                <i className="fas fa-trash-alt fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.redeemEnable}
                                onChange={() => handleEnabledRedeem(data?._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data?.redeemEnable ? "-24px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.redeemEnable ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="16" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalAgencyWiseHost}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {dialog1 && (
        <Dialog
          open={dialog1}
          aria-labelledby="responsive-dialog-title"
          onClose={closePopup}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          sx={{ maxWidth: "400px" }}
        >
          <DialogTitle id="responsive-dialog-title">
            <span className="text-danger font-weight-bold h4">
              {" "}
              Update Host To Another Agency
            </span>
          </DialogTitle>

          <IconButton
            style={{
              position: "absolute",
              right: 0,
            }}
          >
            <Tooltip title="Close">
              <Cancel className="text-danger" onClick={closePopup} />
            </Tooltip>
          </IconButton>
          <DialogContent>
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        {errors.bd && (
                          <div className="ml-2 mt-1">
                            {errors.bd && (
                              <div className="pl-1 text__left">
                                <span className="text-red">{errors.bd}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="form-group">
                        <label className="mb-2 text-gray">
                          Unique Id of User
                        </label>

                        <Select
                          value={hostDropdown?.find(
                            (option) => option?.uniqueId === uniqueId
                          )}
                          styles={customStyles}
                          isSearchable={dialogData1 ? false : true}
                          disabled={dialogData1 ? true : false}
                          options={hostDropdown}
                          getOptionLabel={(option) => option?.uniqueId} // Assuming uniqueId is the label for options
                          formatOptionLabel={(option) => (
                            
                            (
                              <div className="country-option">
                                <img
                                  src={option?.image}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  alt="country-image"
                                />
                                <span className="ms-3">{option?.uniqueId}</span>
                              </div>
                            )
                          )}
                          onChange={(selectedOption) => {
                            
                            setUniqueId(selectedOption?.uniqueId);
                            if (
                              !selectedOption?.uniqueId ||
                              selectedOption?.uniqueId === "uniqueId"
                            ) {
                              setErrors({
                                ...errors,
                                uniqueId: "Please select a uniqueId!",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                uniqueId: "",
                              });
                            }
                          }}
                          onInputChange={(inputValue) => {
                            if (inputValue?.length > 3) {
                              setSearch(inputValue);
                            }
                          }}
                        />
                        {errors.uniqueId && (
                          <div className="ml-2 mt-1">
                            {errors.uniqueId && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.uniqueId}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div class="form-group col-12 my-3">
                        <label className="mb-2 mt-2 text-gray">
                          Select Agency Code
                        </label>

                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          disabled={selectedRequest?.agencyCode === null}
                          value={agencyCode}
                          onChange={(e) => {
                            setAgencyCode(e.target.value);
                            setErrors({ ...errors, agencyCode: "" });
                          }}
                        >
                          <option selected value="Select Code">
                            Select Code
                          </option>
                          {Array.isArray(agencyData) &&
                            agencyData.map((agencyItem) => {
                            
                              return (
                                <option
                                  key={agencyItem?.agencyCode}
                                  value={agencyItem?.agencyCode}
                                >
                                  {agencyItem?.agencyCode}
                                </option>
                              );
                            })}
                        </select>
                        {errors.agencyCode && (
                          <div className="ml-2 mt-1">
                            {errors.agencyCode && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.agencyCode}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={imagePath ? "mt-3 pt-3" : "mt-5"}>
                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                      onClick={closePopup}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-round float__right btn-danger"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default connect(null, { getAgencyWiseHost, handleBlockUnblockSwitch })(
  AgencyWiseHost
);
