import { Link, useNavigate } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { OPEN_DIALOGUE_SETTLEMENT } from "../../../store/settlement/settlement.type";
import { getHostSettlement } from "../../../store/hostSettlement/hostSettlement.action";
import BonusInfo from "./BonusInfo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import SelectCustomStyles from "../../../util/CustomStyle";
import { OPEN_INFO_DIALOGUE_SETTLEMENT } from "../../../store/hostSettlement/hostSettlement.type";

const SettlementSolved = (props) => {
  // const { dialogue, dialogueType, dialogueData } = useSelector(
  //   (state) => state.dialogue
  // );
  const { agencySettlement, historyCount } = useSelector(
    (state) => state.settlement
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(2);
  const [sort, setSort] = useState("_id");
  const [sortType, setSortType] = useState(0);
  const [options, setOptions] = useState([]);


  const [countryData, setCountryData] = useState([]);

  const [country, setCountry] = useState("All");

  console.log("agencySettlementagencySettlement", agencySettlement)

  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all").then((res) => {
      setCountryData(res.data);
    });
  }, []);

  useEffect(() => {
    if (countryData?.length > 0) {
      const formattedOptions = countryData.map((country) => ({
        label: country?.name?.common,
        value: country?.name?.common,
      }));

      // Add the "All" option at the beginning of the array
      setOptions([{ label: "All", value: "All" }, ...formattedOptions]);
    }
  }, [countryData]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleSearch = () => {
    const value = search.trim().toLowerCase();

    if (value) {
      const filteredData = agencySettlement.filter((data) => {
        return (
          data?.name?.toLowerCase().includes(value) ||
          data?.uniqueId?.toString().includes(value) ||
          data?.agencyCode?.toString().includes(value) ||
          data?.totalCoin?.toString().includes(value)
        );
      });
      setData(filteredData);
    } else {
      setData(agencySettlement);
    }
  };

  useEffect(() => {
    let type = 2;
    const payload = {
      startDate,
      endDate,
      type,
      country
    };
    dispatch(getHostSettlement(payload));
  }, [startDate, endDate, country]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId?._id,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
      agencyName: data?.agencyId?.name,
    };
    history?.push("/admin/agencySettlement", { state: state });
  };

  const handleChildValue = (value) => {
    setSortType(sortType === -1 ? 1 : -1);
    setSort(value);
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Solved Host Settlement</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <Select
                    value={options?.find((option) => option?.value === country)}
                    options={options}
                    className="select_country"
                    placeholder="Select Country"
                    styles={SelectCustomStyles}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption?.value);
                    }}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-prepend border-0">
                        <div
                          id="button-addon4"
                          className="btn text-danger"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            <div className="card-body card-overflow">
              <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table className="table table-striped">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    {/* <th>BD </th> */}
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Month</th>
                    <th>Agency</th>
                    <th>Agency Code</th>
                    <th>Host</th>
                    <th>Country</th>
                    <th>Host Id</th>
                    <th>Days</th>
                    <th>Duration</th>
                    <th>Host Total Coin</th>
                    <th>PayoutDate</th>
                    {/* <th>Status</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.length > 0 ? (
                    data.map((value, agencyIndex) => (
                      <tr key={`${agencyIndex}-${agencyIndex}`}>
                        <td>
                          {(currentPage - 1) * currentPage + agencyIndex + 1}
                        </td>
                        <td>{value?.startDate || "-"}</td>
                        <td>{value?.endDate || "-"}</td>
                        <td>{value?.month || "-"}</td>
                        <td><img src={value?.agency?.image} height={20} width={20} style={{ borderRadius: "50%" }} />{value?.agency?.name || "-"}</td>
                        <td>{value?.agency?.agencyCode || "-"}</td>
                        <td><img src={value?.hostImage} height={20} width={20} style={{ borderRadius: "50%" }} />{value?.hostName || "-"}</td>
                        <td>{value?.country || "-"}</td>
                        <td>{value?.hostUniqueId || "-"}</td>
                        <td>{value?.validDays || "-"}</td>
                        <td>{value?.liveDuration || "-"}</td>
                        <td>{value?.amount || "-"}</td>
                        <td>{value?.payoutDate || "-"}</td>
                        <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() =>
                                dispatch({
                                  type: OPEN_INFO_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                  },
                                })
                              }
                            >
                              Paid
                            </button>
                          </Tooltip>
                        </td>

                        {/* <td>
                          <Tooltip title="Info">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => {
                                dispatch({
                                  type: OPEN_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                    type: "settlementActionDialogue",
                                  },
                                });
                              }}
                            >
                              Action
                            </button>
                          </Tooltip>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <SettlementActionDialogue /> */}

              {/* <Pagination
                  activePage={activePage}
                  rowsPerPage={page}
                  userTotal={total}
                  handleRowsPerPage={handleChangePage}
                  handlePageChange={handleChangePage}
                /> */}
            </div>
            {/* <BonusInfo /> */}
          </div>
        </div>
      </div>
      {/* <div className="mainSettlementTable">
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Solved Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-6 my-3">
                <Searching
                  type={"server"}
                  data={agencySettlement}
                  setSearchData={setSearch}
                  setData={setData}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={data?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={historyCount}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div> */}
    </>
  );
};

export default connect(null, { getHostSettlement })(SettlementSolved);
