import React, { useEffect, useState } from "react";
import { CLOSE_SUB_ADMIN_DIALOGUE } from "../../store/subAdmin/types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { getCoinSellerUniqueId } from "../../store/coinSeller/action";
import { addSubAdmin, updateSubAdmin } from "../../store/subAdmin/action";

const SubAdminDialog = () => {
  const { dialog: open, dialogData } = useSelector((state) => state.subAdmin);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [isBan, setIsBan] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [role, setRole] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const dispatch = useDispatch();

  const [errors, setError] = useState({
    email: "",
    password: "",
    role: "",
  });
console.log("dialogDatadialogDatadialogData",dialogData)
  useEffect(
    () => () => {
      setError({
        email: "",
        password: "",
        role: "",
      });
      setEmail("");
      setPassword("");
      setRole("");
    },
    [open]
  );

  useEffect(() => {
    if (dialogData) {
      setEmail(dialogData.email);
      setPassword(dialogData.password);
      setAccount(dialogData.account);
      setName(dialogData.name);
      setMobile(dialogData.mobile);
      setGender(dialogData.gender);
      setIsBan(dialogData.isBan);
    }
  }, [dialogData]);

 

  const onSelect = (selectedList, selectedItem) => {
    setPaymentGateway([...selectedList]);
  };

  const onRemove = (selectedList, removedItem) => {
    setPaymentGateway([...selectedList]);
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_SUB_ADMIN_DIALOGUE });
  };

  const handleSubmit = () => {

    if (!email || !password || !account || !mobile || !gender || !name || !isBan) {
      let error = {};

      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "Password Is Required !";
      if (!account) error.account = "Account Is Required !";
      if (!mobile) error.mobile = "Mobile Is Required !";
      if (!gender) error.gender = "Gender Is Required !";
      if (!name) error.name = "Name Is Required !";
      if (!isBan) error.role = "isBan Is Required";
      return setError({ ...error });
    } else {
      const data = {
        email,
        password: parseInt(password),
        account, mobile, gender, name, isBan
        // roles: paymentGateway,
      };

      if (dialogData) {
        const updateData = {
          ...dialogData,
          ...data,
        };
        dispatch(updateSubAdmin(updateData, dialogData?._id));
      } else {
        dispatch(addSubAdmin(data));
      }

      closePopup();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        // maxWidth="400px"
        sx={{ maxWidth: "400px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Sub Admin </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter account"
                      required
                      value={account}
                      onChange={(e) => {
                        setAccount(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            account: "account can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            account: "",
                          });
                        }
                      }}
                    />
                    {errors.account && (
                      <div className="ml-2 mt-1">
                        {errors.account && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.account}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div class="row d-flex mt-3">
                    <div class={`col-md-12`}>
                      <div class="form-group">
                        <label class="mb-2 text-gray">Password</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);

                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                password: "password can't be a blank!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                password: "",
                              });
                            }
                          }}
                        />
                        {errors.password && (
                          <div className="ml-2 mt-1">
                            {errors.password && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.password}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Admin Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter name"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            name: "name can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            name: "",
                          });
                        }
                      }}
                    />
                    {errors.name && (
                      <div className="ml-2 mt-1">
                        {errors.name && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.name}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            email: "email can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            email: "",
                          });
                        }
                      }}
                    />
                    {errors.email && (
                      <div className="ml-2 mt-1">
                        {errors.email && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.email}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter phone number"
                      required
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            mobile: "mobile can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            mobile: "",
                          });
                        }
                      }}
                    />
                    {errors.mobile && (
                      <div className="ml-2 mt-1">
                        {errors.mobile && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.mobile}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label className="mb-2 text-gray">Gender</label>
                    <select
                      className="form-control"
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);

                        if (!e.target.value) {
                          setError({
                            ...errors,
                            gender: "Gender can't be blank!",
                          });
                        } else {
                          setError({
                            ...errors,
                            gender: "",
                          });
                        }
                      }}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender && (
                      <div className="ml-2 mt-1">
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.gender}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label className="mb-2 text-gray">Status</label>
                    <select
                      className="form-control"
                      value={isBan}
                      onChange={(e) => {
                        setIsBan(e.target.value);

                        if (!e.target.value) {
                          setError({
                            ...errors,
                            isBan: "Status can't be blank!",
                          });
                        } else {
                          setError({
                            ...errors,
                            isBan: "",
                          });
                        }
                      }}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="false">Unbanned</option>
                      <option value="true">Banned</option>
                    </select>
                    {errors.isBan && (
                      <div className="ml-2 mt-1">
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.isBan}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div class="col-12 mt-3">
                    <label for="googlePlayEmail" class="form-label">
                      Roles
                    </label>

                    <Multiselect
                      options={userRole}
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                    />
                    {errors.role && (
                      <div className="ml-2 mt-1">
                        {errors.role && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.role}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div> */}
                </div>

                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      
    </div>
  );
};

export default SubAdminDialog;


