import {
  OPEN_AGENCY_DIALOG,
  CLOSE_AGENCY_DIALOG,
  GET_AGENCY,
  CREATE_NEW_AGENCY,
  EDIT_AGENCY,
  SET_CREATE_AGENCY_DONE,
  UNSET_CREATE_AGENCY_DONE,
  SET_UPDATE_AGENCY_DONE,
  UNSET_UPDATE_AGENCY_DONE,
  ENABLE_DISABLE_AGENCY,
  GET_AGENCY_DROPDOWN,
  GET_AGENCY_WISE_HOST,
  BLOCK_UNBLOCK_SWITCH_AGENCYUSERS,
  ACCEPT_DECLINE_AGENCY,
  GET_UNVERIFIED_AGENCY,
  REDEEM_ENDABLED_SWITCH_AGENCY,
  REDEEM_ENDABLED_SWITCH_HOST,
  DELETE_AGENCYWISEHOST,
  OPEN_AGENCYWISEHOST_DIALOG,
  CLOSE_AGENCYWISEHOST_DIALOG,
  UPDATE_HOST_TO_ANOTHER_AGENCY,
  GET_HOST_DROPDOWN,
  GET_HOST_AGENCY_CODE,
} from "./type";

const initialState = {
  agency: [],
  total: 0,
  agencyWiseHost: [],
  totalAgencyWiseHost: 0,
  agencyDropdown: [],
  hostDropdown: [],
  agencyCodeDropDown: [],
  dialog: false,
  dialogData: null,
  dialog1: false,
  dialogData1: null,
  createDone: false,
  updateDone: false,
};

const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENCY:
      return {
        ...state,
        agency: action.payload.data,
        total: action.payload.total,
      };

    case GET_UNVERIFIED_AGENCY:
      return {
        ...state,
        agency: action.payload.data,
      };

    case ACCEPT_DECLINE_AGENCY:
      return {
        ...state,
        agency: state.agency.filter((agency) => agency?._id !== action.payload),
      };
    case GET_AGENCY_WISE_HOST:
      return {
        ...state,
        agencyWiseHost: action.payload.data,
        totalAgencyWiseHost: action.payload.total,
      };
    case BLOCK_UNBLOCK_SWITCH_AGENCYUSERS:
      return {
        ...state,
        agencyWiseHost: state.agencyWiseHost.map((agency) => {
          if (agency._id === action.payload._id)
            return {
              ...agency,
              isBlock: action.payload.isBlock,
            };
          else return agency;
        }),
      };
    case REDEEM_ENDABLED_SWITCH_HOST:
      return {
        ...state,
        agencyWiseHost: state.agencyWiseHost.map((agency) => {
          if (agency._id === action.payload._id)
            return {
              ...agency,
              redeemEnable: action.payload.redeemEnable,
            };
          else return agency;
        }),
      };
    case REDEEM_ENDABLED_SWITCH_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency?._id === action?.payload?._id)
            return {
              ...agency,
              redeemEnable: action.payload.redeemEnable,
            };
          else return agency;
        }),
      };
    case GET_AGENCY_DROPDOWN:
      return {
        ...state,
        agencyDropdown: action.payload,
      };

    case GET_HOST_DROPDOWN:
      return {
        ...state,
        hostDropdown: action.payload,
      };

    case GET_HOST_AGENCY_CODE:
      return {
        ...state,
        agencyCodeDropDown: action.payload,
      };

    case CREATE_NEW_AGENCY:
      const data = [...state.agency];
      data.unshift(action.payload);
      return {
        ...state,
        agency: data,
      };

    case DELETE_AGENCYWISEHOST:
      return {
        ...state,
        agencyWiseHost: state.agencyWiseHost.filter(
          (item) => item._id !== action.payload
        ),
      };

    case EDIT_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency._id === action.payload.id) return action.payload.data;
          else return agency;
        }),
      };

      // case UPDATE_HOST_TO_ANOTHER_AGENCY:
      //   return {
      //     ...state,
      //     agencyWiseHost: state.agencyWiseHost.filter((item) => {
      //       return item._id !== action.payload; // Ensure you return a boolean value
      //     }),
      //   };
      
    case SET_CREATE_AGENCY_DONE:
      return {
        ...state,
        createDone: true,
      };
    case UNSET_CREATE_AGENCY_DONE:
      return {
        ...state,
        createDone: false,
      };
    case SET_UPDATE_AGENCY_DONE:
      return {
        ...state,
        updateDone: true,
      };
    case UNSET_UPDATE_AGENCY_DONE:
      return {
        ...state,
        updateDone: false,
      };
    case OPEN_AGENCY_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    case OPEN_AGENCYWISEHOST_DIALOG:
   
      return {
        ...state,
        dialog1: true,
        dialogData1: action.payload || null,
      };

    case CLOSE_AGENCYWISEHOST_DIALOG:
      return {
        ...state,
        dialog1: false,
        dialogData1: null,
      };
    case CLOSE_AGENCY_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case ENABLE_DISABLE_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency?._id === action?.payload?._id)
            return {
              ...agency,
              isActive: action?.payload?.isActive,
            };
          else return agency;
        }),
      };
    default:
      return state;
  }
};

export default agencyReducer;
